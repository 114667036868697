.App {
  text-align: center;
  position:relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Wrapper needs to be relative */
.fade {
  position: relative;
}

/* Position transitions absolutely */
.fade-enter, .fade-exit {
  position: absolute;
  width: 100%;
}

.fade-enter {
  opacity: 0;
  z-index: 1; /* Put the entering element above the exiting one */
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}


.topBar{
  background: rgba(254, 254, 254, 0.86);
  align-items: center;
  color: black;
  width: 314px;
  text-align: justify;
  margin-top: 530.5px;
  display: flex;
}

.welcome {
  color: white;
  top: -220px;
  text-align: center;
  align-items: center;
  margin-top: -150px;
  font-size: calc(20px + 0.5vw);
  position:relative;
  
  
}

.body-aboutme{
  color: white;
  font-size: calc(12px + 0.5vw);

}

.socials {
  top: 10px;
  margin-top: -650px;
  margin-left: 175px;
}

.background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background: no-repeat;
  background-size: cover;
}



.Background {
  /*background-image: url("./background.jpeg");
  background-repeat: repeat-y;
  background-size:cover;*/
  min-height: 40vw;
  height: 100%;
  width: 100%;
  position:fixed;
  
}

.resume{
  font: 30px;
  margin-top: -730px;
  color: white;
  
}

.App-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.contactsbox{
  margin-left: 24px;
}
